import React from 'react'
import { Link } from 'gatsby'
import styles from './footer.module.css'

export default () => (
    <footer>
      <div className={styles.wrapperFooter}>
          <div className={styles.footerLinks}>
              <ul>
                  <div className={styles.linkItem}>
                    <li><Link to="/om">Om</Link></li>
                    <li><Link to="/medlem/styrelse">Styrelse</Link></li>
                    <li><Link to="/bilder">Bilder</Link></li>
                  </div>
                  <div className={styles.linkItem}>
                    <li><Link to="/blog">Aktuellt</Link></li>
                    <li><Link to="/kontakt">Kontakt</Link></li>
                    <li><Link to="/medlem">Medlem</Link></li>
                  </div>
              </ul>
          </div>
          <div className={styles.cynesige}>
            <a  href="https://cynesige.net"> © {new Date().getFullYear()}, Cynesige Development</a>
          </div>
      </div>
    </footer>
  )