import React from 'react'
import { Link } from 'gatsby'
//import styles from './navigation.module.css'

export default () => (
  <nav role="navigation">
    <ul className="navLinks" style={{listStyle: 'none'
        }}>
            <li><Link to="/">Fornbod</Link> </li>
            <li><Link to="/om">Om</Link> </li>
            <li><Link to="/medlem/styrelse">Styrelse</Link> </li>
            <li><Link to="/kontakt">Kontakt</Link> </li>
            <li><Link to="/bilder">Bilder</Link> </li>
            <li><Link to="/blog">Aktuellt</Link> </li>
            <li><Link to="/medlem">Medlem</Link> </li>
          </ul>
          <div className="burger">
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
  </nav>
)
