
import Layout from "../components/layout";
import Gallery from "@browniebroke/gatsby-image-gallery";
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import { graphql } from 'gatsby'
import React from 'react'


const IndexPage = ({ data }) => {
  const images = data.images.edges.map(({ node }) => node.childImageSharp)

  return (
    <Layout>
      <h1>Bildgalleri</h1>
      <Gallery images={images} />
    </Layout>
  )
}

export const query = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage